:root {
  --main-bg: #292929;
  --main-frame-padding: 3.5vw;
  --margin-right-desk: 30px;
  --margin-right-tablet: 40px;
  --font-family: "Space Grotesk";
  --font-color: black;
  --serve-color: yellow;
}
html {
  font-family: var(--font-family, "Space Grotesk"), sans-serif;
  color: white;
}

body {
  min-height: 100vh;
  width: 100%;
  background-color: var(--main-bg, black);
}

.main {
  @apply relative w-full h-full min-h-screen overflow-x-hidden flex flex-col;
  padding: var(--main-frame-padding, 0);
}

.competition-logo {
  width: 15vw;
  max-width: 300px;
}

.wrapper {
  width: 100%;
  padding: 0px;
  margin: 0 auto;
}

@media (min-width: 1600px) {
  .wrapper {
    padding: 0px;
  }
}

#top-title {
  color: white;
  font-size: 2.4vw;
}

#match-title {
  line-height: 1.3;
  color: white;
  font-size: 4.4vw;
}

.score-grid {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #666666;
}

.score-grid > div:first-child {
  width: 25%;
  flex: 1 1 50%;
  margin-right: var(--margin-right-mid);
}

.sets {
  margin-left: auto;
  display: flex;
  min-width: max-content;
  flex-shrink: 0;
}

.set,
.set-score {
  text-align: center;
  flex-shrink: 0;
  margin-right: 0.5vw;
  width: 7.5vw;
}
.App:has(.set-control) {
  .set:last-child {
    width: 10vw;
  }
}
.round,
.set {
  @apply uppercase font-medium;
  font-size: calc(2.5vw);
  transition: all 0.2s;
}
.game {
  span {
    @apply uppercase;
    font-size: calc(2.5vw);
  }
}

.flag {
  margin-right: 0.8vw;
  width: 5vw;
  height: 100%;
  max-width: 92px;
  max-height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag img {
  width: auto;
  height: 100%;
  object-fit: contain;
  max-height: 53px;
}

.flag.not-picked {
  background-color: #405275;
}

.player-name {
  line-height: 1;
  font-size: calc(0.5rem + 3vw);
}

.serve {
  margin-left: 0.8vw;
  width: 2vw;
  max-width: 40px;
  height: 2vw;
  max-height: 40px;
}

.serve.serve-active {
  background: var(--serve-color, lightgray);
  opacity: 0.9;
}

.game {
  /* color: var(--font-color, inherit); */
  color: white;
}

.game-score,
.game {
  flex-shrink: 0;
  text-align: center;
  overflow: hidden;
  line-height: 1;
  display: flex;
  justify-content: center;
  width: calc(2ch + 0.1vw);
}
.set-control {
  @apply absolute top-1/2 -translate-y-1/2 left-0  rounded-full  text-black;
  svg {
    width: 2.5vw;
    height: 2.5vw;
  }
  &.set-minus {
    @apply bg-red-300;
  }
  &.set-plus {
    left: calc(76%);
    @apply bg-green-300;
  }
}
.set-score {
  font-weight: 500;
}

.set-score,
.game-score,
.game {
  letter-spacing: -0.01em;
  position: relative;
  line-height: 1;
  font-size: calc(9.5vw);

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
.game {
  overflow: visible;
}
.set-score:has(.set-control) {
  width: 10vw;
}

.game-score {
  font-weight: 700;
  color: black;
  background-color: white;
  height: auto;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  display: flex;
}

.game-score.gray {
  color: var(--font-color, inherit);
  background-color: #dcddde;
}

.time {
  font-size: 6vw;
  font-weight: 700;
}

.sponsors-list > p {
  font-size: 4vw;
  font-weight: 500;
  margin-left: calc(4px + 0.5vw);
}
.match-controls {
  @apply flex flex-wrap gap-2 my-2;
  font-size: 3vw;
  > div {
    flex: 1 1 calc(50% - 20px);
    @apply flex flex-col gap-2 text-center;
  }
}
div.time-control {
  flex: 1 1 calc(100% - 20px);
}
.game-control {
  font-size: 5vw;
}
@media screen and (min-width: 768px) {
  .match-controls {
    > div {
      flex: 1 1 calc(50% - 20px);
    }
  }
}

@media screen and (min-width: 1000px) {
}

@media screen and (min-width: 1280px) {
  .match-controls {
    @apply w-full;
    > div {
      flex: 1 1 calc(20% - 20px);
      > button {
        @apply w-3/4 mx-auto;
      }
    }
    font-size: 2vw;
  }
  div.time-control {
    flex: 1 1 calc(20% - 20px);
  }
  .game-control {
    flex: 1 1 calc(20% - 20px);
    font-size: 2vw;
  }
}

@media screen and (min-width: 1600px) {
  .score-grid {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 2px;
  }

  .score-grid > div:first-child {
    width: 25%;
    flex: 1 1 50%;
    margin-right: var(--margin-right-desk);
  }

  .sets {
    margin-left: auto;
    display: flex;
    min-width: max-content;
    flex-shrink: 0;
  }

  .set,
  .set-score {
    width: 6vw;
    text-align: center;
    margin-right: var(--margin-right-desk);
  }

  .set {
    width: 6vw;
  }
}

@media screen and (min-width: 2560px) {
}
