@tailwind base;
@tailwind components;
@tailwind utilities;



html {
  font-family: 'Space Grotesk', sans-serif;
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: white !important;
  color: white !important;
}